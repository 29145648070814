.rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0;
    float: right;
    font-size: 2rem;
    margin: 0 2rem;
    padding: 0;
    border: none;
    color: brown;
}

.rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 0;
    margin: 0 0.5rem;
    font-size: 2rem;
    margin: 0 2rem;
    padding: 0;
    border: none;
    color: brown;
}


