$sub-color: grey;
$main-color: rgb(85, 135, 182);

@mixin shrinkLabel {
  top: -14px;
  font-size: 12px;
  color: $main-color;
}

.group-exam {
  position: relative;

  .form-input {
    background: none;
    background-color: white;
    color: $sub-color;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 15px 0;

    &:hover {
      border-bottom: 1px solid $sub-color;
    }
    &:focus {
      outline: none;
      border-bottom: 2px solid $main-color;
    }

    &:focus ~ .form-input-label {
      @include shrinkLabel();
    }
  }

  input[type="password"] {
    letter-spacing: 0.3em;
  }

  .form-input-label {
    color: $sub-color;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    right: 5px;
    top: 10px;
    transition: 300ms ease all;

    &.shrink {
      @include shrinkLabel();
    }
  }
}
