#home {
  background-color: rgb(255, 255, 255);
}

.muted {
  color: green($color: #777171) !important;
}

.image-card {
  border-radius: 6px;
  max-width: 60%;
}

.grid-2-1 {
  display: flex;
  justify-content: space-around;

  @media (max-width: 700px) {
    flex-direction: column;
    padding-right: 2.6rem;
  }
}

.col-m5 {
  flex: 1;
  margin: 0 0.5rem;
}
.col-m3 {
  flex: 3;
  margin: 0 0.5rem;
}
