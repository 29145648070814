.align-left {
  text-align: left;
}
.card ol {
  padding-right: 1rem;
}

.flex {
  display: flex;
  justify-content: center;

  @media (max-width: 700px) {
    flex-direction: column;
    padding-right: 2.6rem;
  }
}

.FormInput {
  flex: 10;
}

.radio-input {
  flex: 1;
  background: none;
  background-color: white;
  color: grey;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  border: none;
  display: block;
  border-radius: 0;
  border-bottom: 1px solid grey;
  margin: 2.2rem 0 !important;
}

.exam-link {
  text-align: center;
  flex: 1;
  margin: 2rem 0 !important;
}
.pr-5 {
  padding-right: 5.6rem !important;
}
.text-danger {
  color: red !important;
}
body {
  background-color: #eee;
}

.card {
  background-color: #fff;
}
.btn-outline {
  text-align: center;
  border: dotted 1px #274850 !important;
}
