 #header{
    background-size: 100% 170% !important;
} 
#courses-header{
    position: relative;
    height: 30vh;
    width: 70vw;
    color: #fff;
    line-height: 160px;
    text-align: center;
    background : url(/img/subject/33.jpg) center   ;
    border-radius : 5px;
    background-size: 100% 230%;
    margin: 5px auto;
}
.Container_x{
    background: #fff;
    line-height: 160px;
    text-align: center;
    border-radius : 5px;
    margin: 5px auto;
}
#courses-header h3{
    height: 100% ;
    border-radius: 5px;
    font-size: 1.5rem;
    background-color: rgba(78, 76, 76, 0.3);
    color :#fff
}
#course-body{
    width: 70vw;
    margin: 2px auto;
}
.ant-card-cover {
    position: relative;
}
.ant-card-cover img{
    position: absolute;
    border-radius: 8px 8px 0 0 ;
    height: 130px;
}
.ant-card-cover .ant-avatar{
    position: absolute;
    top: 5px;
    right: -12px;
    z-index: 222;
    width: 20%;
    height: 28px;
    background: rgb(213,76,52);
    background: linear-gradient(90deg, rgba(213,76,52,1) 0%, rgba(221,103,83,1) 47%, rgba(231,182,172,0.9794292717086834) 97%);
}
.ant-card {
    position: relative;
    margin: 0.8rem 0.7rem;
}
.ant-card-actions{
    position: relative;
    bottom: -120px;
}
.ant-card-body{
    position: relative;
    bottom: -120px;

}
.ant-card {
    height: 180px;
    width: 240px;
}

#course-body .ant-list-header h3>a ,#course-body .ant-list-item>.lesson-config{
    position: relative;
    float: left;   
     display: none !important;
     margin: 0 0.2rem;
     font-size: 1.1rem;
}


#course-body .ant-list-header:hover  h3>a ,#course-body .ant-list-item:hover >.lesson-config{
    display :inline-block !important;
}