#subject {
  background-color: rgb(231, 235, 240);
  min-height: 100vh;
}

.card-container {
  margin: 1rem 6.7rem 1rem 0.4rem;
}

.btn-outline {
  text-align: center;
  border: dotted 1px #274850;
}

.table-image {
  max-width: 6rem;
  border-radius: 6px;
}
table {
  width: 100%;
  text-align: center;
  border-radius: 6px;
}
.center-align {
  text-align: center;
}

.file-icons {
  font-size: 2rem;
}

.card.horizontal {
  display: flex;
}
.card .card-image {
  flex: 1;
}
.card-stacked {
  flex: 1;
  padding: 1rem;
}
